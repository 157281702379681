<template>
  <div class="event-box">
    <div class="event-header">
      <div class="event-name">
        <!-- 重大事件：lable.PowerProcess.big.event -->
        <h3>{{ $t("lable.PowerProcess.big.event") }}</h3>
        <h3>{{ bigEventName }}</h3>
      </div>
      <div class="event-Tips">
        <!--lable.PowerProcess.event.msg 您可以在权利过程中添加每个步骤（重大事件）都会发生的依赖于时间的工作流操作。例如，您可以设置操作，以在重大事件过期前一小时向个案所有人发送电子邮件，或者在首次回复重大事件完成时对自动更新特定个案字段 -->
        <h5>
         {{ $t("lable.PowerProcess.event.msg") }}
        </h5>
        <!-- 在创建过程前，请 lable.PowerProcess.created.ago.please-->
        <!-- 创建重大事件 lable.PowerProcess.created.event-->
        <h5>
          {{ $t("lable.PowerProcess.created.ago.please") + '&nbsp;' }}
          <a
            href="javascript:void(0);"
            class="created-big-event"
            @click="createdBigEvent"
            >{{ $t("lable.PowerProcess.created.event") }}</a
          >
        </h5>
      </div>
    </div>
    <div class="event-edit">
      <div class="event-edit-top">
        <!-- 查看重大事件 lable.PowerProcess.see.page-->
        <h3>{{ $t("lable.PowerProcess.see.page") }}</h3>
        <div class="save-event">
          <!-- 返回  label.emailobject.emaildetail.button.back-->
          <el-button @click="CancelSave">{{ $t("label.emailobject.emaildetail.button.back") }}</el-button>
        </div>
        <div>
          <span>*</span>
          <!-- 必填信息 label.requiredinfo-->
          <span>{{ $t("label.requiredinfo") }}</span>
        </div>
      </div>
      <div class="event-edit-detail">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <div class="interest-edit-name">
            <!-- 权利过程 lable.PowerProcess.name-->
            <span>{{ $t("lable.PowerProcess.name") }}</span>
            <span>{{ bigEventName }}</span>
          </div>
          <div class="event-edit-name">
            <div class="big-event-name">
              <span>*</span>
              <!-- 重大事件名称 lable.PowerProcess.event.name-->
              <span>{{ $t("lable.PowerProcess.event.name") }}</span>
            </div>
            <div>
              <el-form-item prop="eventID">
                <!-- -无- label.h5.date.none-->
                <el-select
                  v-model="ruleForm.eventID"
                  :placeholder="$t('label.h5.date.none')"
                  :disabled="true"
                  @change="chioceName"
                >
                  <el-option
                    v-for="item in eventNames"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="event-edit-TimeTriggered">
            <div class="TimeTriggered">
              <!-- 时间触发器(分钟) lable.PowerProcess.time.trigger-->
              <span>*</span><span>{{ $t("lable.PowerProcess.time.trigger") }}</span>
            </div>
            <div class="TimeTriggered-value">
              <el-form-item prop="TimeTriggered">
                <el-input
                  v-model="ruleForm.TimeTriggered"
                  :disabled="true"
                  placeholder=""
                ></el-input>
              </el-form-item>
              <svg  aria-hidden="true" class="icon">
                    <use href="#icon-Tips"
                          ></use>
                 </svg>
            </div>
          </div>
          <div class="event-edit-starTime">
            <!-- 开始时间 label.import.index.starttime-->
            <div class="start-time"><span>*</span><span>{{$t('label.import.index.starttime')}}</span></div>
            <div>
              <el-form-item prop="startTimeId">
                <!-- -无- label.h5.date.none-->
                <el-select
                  v-model="ruleForm.startTimeId"
                  :placeholder="$t('label.h5.date.none')"
                  :disabled="true"
                  @change="chioceCondition"
                >
                  <el-option
                    v-for="item in startTime"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :disabled="item.disabled"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="event-edit-order">
            <!-- 顺序 label.newprocess5.seq-->
            <div class="order"><span>*</span><span>{{$t('label.newprocess5.seq')}}</span></div>
            <div class="order-value">
              <el-form-item prop="order">
                <el-input
                  v-model="ruleForm.order"
                  placeholder=""
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <svg  aria-hidden="true" class="icon">
                    <use href="#icon-Tips"
                          ></use>
                 </svg>
            </div>
          </div>
          <div class="edit-condition" v-if="isEventCondition">
            <!-- 需要满足以下条件： lable.PowerProcess.Meet.the.conditions-->
            <h4>{{ $t("lable.PowerProcess.Meet.the.conditions")}}</h4>
            <div class="Operation-type">
              <!-- 字段 label.tabpage.field-->
              <!-- 运算符 label.operator-->
              <!-- 值 label.reportsSubscriptionPage.condition.value-->
              <span>{{ $t("label.tabpage.field") }}</span>
              <span>{{ $t("label.operator") }}</span> 
              <span>{{ $t("label.reportsSubscriptionPage.condition.value") }}</span>
            </div>
            <div class="range">
              <!-- 筛选字段文本框 -->
              <div
                v-for="(val, index) in ruleForm.pushData"
                :key="val.id"
                style="display: flex; margin-bottom: 10px"
              >
                <div class="vl" style="display: flex">
                  <!-- 筛选字段 开始 -->
                  <!-- 请选择字段 label.processsetting.please.select.field-->
                  <el-form-item
                    :prop="`pushData.${index}.fieldId`"
                    :rules="[
                      {
                        required: true,
                        message: $i18n.t('label.processsetting.please.select.field'),
                        trigger: 'blur',
                      },
                    ]"
                  >
                  <!-- 选择字段 label.selectfield-->
                    <el-select
                      v-model="val.fieldId"
                      :placeholder="$i18n.t('label.selectfield')"
                      filterable
                      :disabled="true"
                      popper-class="option"
                      :popper-append-to-body="false"
                    >
                      <el-option
                        v-for="(items, indexs) in choseFieldList"
                        :key="indexs"
                        :label="items.labelName"
                        :value="items.id"
                        @click.native="handleCommand(val, items, items)"
                      >
                        <!-- val:传递当前字段的信息 -->
                        <!-- items:传递 -->
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <!-- 筛选条件 开始 -->
                  <!-- 请选择条件 lable.PowerProcess.condition.input-->
                  <el-form-item
                    :prop="`pushData.${index}.op`"
                    :rules="[
                      {
                        required: true,
                        message: $i18n.t('lable.PowerProcess.condition.input'),
                        trigger: 'blur',
                      },
                    ]"
                  >
                  <!-- 选择条件 label.report.select.condition-->
                    <el-select
                      v-model="val.op"
                      :placeholder="$i18n.t('label.report.select.condition')"
                      class="select"
                      :disabled="true"
                      filterable
                      popper-class="option"
                      :popper-append-to-body="false"
                    >
                      <el-option
                        v-for="item in conditions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="fieldValue">
                  {{ val.val.value }}
                </div>
              </div>
              <div>
                <!-- 搜索 label.searchs-->
                <el-dialog
                  :title="$t('label.searchs')"
                  custom-class="dialog-form-wrapper"
                  width="60%"
                  top="5%"
                  style="height: 91%"
                  :visible.sync="showSearchTable"
                  :lock-scroll="true"
                  :close-on-click-modal="false"
                  :modal="true"
                  append-to-body
                >
                  <search-table
                    ref="searchTable"
                    :fieldId="fieldId"
                    :checked="checked"
                    :relevant-objid="relevantObjId"
                    :relevant-prefix="relevantPrefix"
                    @changeSelect="changeSelect"
                  />
                </el-dialog>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <div class="save-bottom">
        <!-- 返回 -->
        <el-button @click="CancelSave">{{ $t("label.emailobject.emaildetail.button.back") }}</el-button>
      </div>
    </div>
  </div>
</template>




<script>
// import EditableCell from "@/components/TablePanel/EditableCell.vue";
import operator from "@/config/enumCode/operator.js";
import inputType from "@/config/enumCode/inputType.js";
import {
  GetCurrencyInfo,
  GetViewInfo,
  getRecordType,
  getSelectValue,
  GetViewGetSelectValue,
} from "./api";
import * as majorEventApi from "./api.js";
export default {
  data() {
    //自定义校验规则-只能输入纯数字
    //请输入时间 lable.PowerProcess.time.input
    //请输入整数 lable.PowerProcess.number.input
    var OnlyNumbers = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$i18n.t("lable.PowerProcess.time.input")));
      } else {
        if (!/^\d+$/.test(this.ruleForm.TimeTriggered)) {
          callback(new Error(this.$i18n.t("lable.PowerProcess.number.input")));
        } else {
          callback();
        }
      }
    };
    //自定义验证规则-顺序
    //请输入顺序 lable.PowerProcess.seq.input
    //请输入整数 lable.PowerProcess.number.input
    var orderNumber = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$i18n.t("lable.PowerProcess.seq.input")));
      } else {
        if (!/^\d+$/.test(this.ruleForm.order)) {
          callback(new Error(this.$i18n.t("lable.PowerProcess.number.input")));
        } else {
          callback();
        }
      }
    };
    return {
      bigEventName: this.$route.query.name,
      eventNames: [],
      isedit: false,
      ruleForm: {
        eventID: "",
        TimeTriggered: "",
        startTimeId: "",
        order: "",
        // 保存筛选字段
        pushData: [
          {
            fieldId: "",
            op: "",
            val: {
              value: "",
            },
            inputType: "input",
          },
        ],
      },
      rules: {
        eventID: [
          //请选择重大事件 lable.PowerProcess.chioce.event
          { required: true, message:this.$i18n.t('lable.PowerProcess.chioce.event'), trigger: "blur" },
        ],
        TimeTriggered: [{ validator: OnlyNumbers, trigger: "blur" }],
        //请选择开始时间 lable.PowerProcess.start.time
        startTimeId: [
          { required: true, message: this.$i18n.t('lable.PowerProcess.start.time'), trigger: "blur" },
        ],
        order: [{ validator: orderNumber, trigger: "blur" }],
      },
      startTime: [],
      // value: "",
      
      pushDatas: {
        fieldId: "",
        op: "",
        val: {
          value: "",
        },
        inputType: "input",
      },
      //筛选条件
      choseFieldList: [],
      conditions: [],
      selector: [],
      showBut: [false],
      showSearchTable: false,
      isEventCondition: false,
      fieldId: "",
      relevantObjId: "",
      relevantPrefix: "",
      // filter: "filter",
      // idNum: 0,
      describeName: this.$route.query.type,
      checked: false, //多选框选择和取消
      //权力过程下重大事件id
      editNeedId: "",
    };
  },
  components: {
    // EditableCell,
  },
  mounted() {
    this.choseField();
    this.getMajoreventList();
    if (this.$route.query.eventDetail) {
      this.editEvent();
    }
  },
  methods: {
    
    remoteMethods(val, fieldId) {
      let params = {
        fieldId: fieldId,
        objId: "",
        prefix: "",
      };
      majorEventApi.getLookupInfo(params).then((res) => {
        let data = {
          name: val,
          objId: res.data.prefix,
          fieldId: params.fieldId,
          lkdp: "['']",
        };
        majorEventApi.queryMoreDataByName(data).then((res) => {
          let options = JSON.parse(res.data);
          options.forEach((option) => {
            option.value = option.id;
            option.label = option.queryname;
          });
          this.selector = options;
        });
      });
    },
    //编辑回显重大事件
    editEvent() {
      let params = JSON.parse(this.$route.query.eventDetail);
      this.ruleForm.order = params.seq;
      this.ruleForm.TimeTriggered = params.timetrigger;
      this.ruleForm.eventID = params.majoreventtypeid;
      this.editNeedId = params.id;
    },
    //清空筛选器数据
    
    clear() {
      this.pushData = [
        {
          id: 0,
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          inputType: "input",
        },
      ];
    },
    //跳转创建重大事件页
    createdBigEvent() {
      this.$router.push({
          path: "/systemSettings/setuppage/importantEvent/edit",
          query: {
            isEditMode:false
          },
        });
    },
    //取消保存
    CancelSave() {
      this.$router.push({
        path: "/systemSettings/setuppage/Administration",
        query: {
          isGoDetail: true,
          goDetailId: this.$route.query.id,
        },
      });
    },
    //表单验证
    
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveEventsProcessOfPreserving();
        } else {
          return false;
        }
      });
    },
    //选择重大事件名称
    chioceName(id) {
      this.ruleForm.startTimeId = "";
      let starTimesArray = [
        { //权利过程 lable.PowerProcess.name
          value: "1",
          label: this.$i18n.t('lable.PowerProcess.name'),
          disabled: false,
        },
        { //重大事件条件  lable.PowerProcess.event.condition
          value: "2",
          label:this.$i18n.t('lable.PowerProcess.event.condition'),
        },
      ];
      this.eventNames.forEach((item) => {
        if (item.id == id) {
          if (item.type != "3") {
            starTimesArray[0].disabled = true;
          } else {
            starTimesArray[0].disabled = false;
          }
        }
      });
      this.startTime = starTimesArray;
    },
    //保存权利过程中的重大事件
    
    async saveEventsProcessOfPreserving() {
      let params = {};
      //如果是编辑后保存
      if (this.isedit) {
        params.id = this.editNeedId;
      }
      params.majoreventtypeid = this.ruleForm.eventID;
      params.entitlementprocessid = this.$route.query.id;
      params.timetrigger = parseInt(this.ruleForm.TimeTriggered);
      params.starttimetype = this.ruleForm.startTimeId;
      params.conditiontype = "condition";
      params.seq = parseInt(this.ruleForm.order);
      if (this.ruleForm.startTimeId == "2") {
        let arrData = [];
        this.ruleForm.pushData.map((item) => {
          let object = {
            fieldId: item.fieldId,
            op: item.op,
            val: "",
          };
          arrData.push(object);
        });
        if (this.$refs.editableCell) {
          this.$refs.editableCell.forEach((item, index) => {
            if (item.editValue !== "") {
              arrData[index].val = item.editValue;
            } else {
              arrData[index].val = item.value;
            }
          });
        }
        if (arrData.length > 0) {
          // 验证筛选器是否填写完整
          for (var key in arrData) {
            var item = arrData[key];
            if (item.fieldId !== "") {
              if (item.val.value == "" || item.op == "") {
                this.$message({
                  showClose: true,
                  type: "warning",
                  // 请填写完整筛选条件
                  message: this.$i18n.t(
                    "vue_label_checkduplicate_complete_filter_rules"
                  ),
                });
                return false;
              } else if (item.val.value != "" && item.op != "") {
                if (Array.isArray(item.val)) {
                  item.val = item.val.toString();
                }
                let str = "1";
                if (arrData.length == 1) {
                  str = "1";
                } else if (arrData.length > 1) {
                  arrData.map((item, index) => {
                    if (index >= 1) {
                      str += ` AND ${index + 1} `;
                      // str = str.replace(/AND $/, "");
                    }
                  });
                }
                params.conditionVals = JSON.stringify({
                  data: arrData,
                  filter: str,
                });
              }
            }
          }
        }
      }
      let reslutData = await majorEventApi.saveEventInEntitlementProcess(
        params
      );
      if (reslutData.result) {
        this.isedit = false;
        this.$router.push({
          path: "/systemSettings/setuppage/Administration",
          query: {
            isGoDetail: true,
            goDetailId: this.$route.query.id,
          },
        });
      }
    },
    //选择条件
    chioceCondition(value) {
      if (value == "2") {
        this.isEventCondition = true;
      } else {
        this.isEventCondition = false;
      }
    },
    //获取重大事件列表
    async getMajoreventList() {
      let resultData = await majorEventApi.getMajoreventTypeList({
        pageNum: 0,
        pageSize: 0,
      });
      if (resultData.result) {
        this.eventNames = resultData.data.majoreventTypeList;
        if (this.$route.query.eventDetail) {
          let params = JSON.parse(this.$route.query.eventDetail);
          this.chioceName(params.majoreventtypeid);
          this.ruleForm.startTimeId = params.starttimetype;
        }
      }
    },
    //判断选择添加如果选择字段获取的值为S则调用S对象,获取的值为D则调用D对象,获取的值为F则调用F对象,获取的值为O则调用O对象
    async handleCommand(item, key, index) {
      this.selector = [];
      let keys = "";
      if (key.schemefieldType === "Z") {
        keys = key.expressionType;
      } else {
        keys = key.schemefieldType;
      }
      this.conditions = operator.TYPE[keys].getScreeningOpeTypes();
      this.ruleForm.pushData.map((item) => {
        if (index.id === item.fieldId) {
          item.op = "";
          item.type =
            key.schemefieldType === "Z"
              ? key.expressionType
              : key.schemefieldType;
          // this.$refs.editableCell[indexs].editValue = "";
        }
      });
      this.ruleForm.pushData.forEach((items) => {
        if (item.id === items.id) {
          // item.inputType = inputType[keys === "L" ? "Q" : keys];
          item.inputType = inputType[keys];
        }
      });
      // 当前选中字段类型
      this.conditions = operator.TYPE[keys].getScreeningOpeTypes();
      if (keys == "ct") {
        let res = await GetCurrencyInfo();
        res.data.forEach((item) => {
          let obj = {
            label: item.currencyname,
            value: item.currencycode,
          };
          this.selector.push(obj);
        });
      }
    },
    
    remoteSearch(item, index) {
      if (item.fieldId !== "") {
        this.fieldId = item.fieldId;
      }
      this.rowIndex = index + 1;
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    
    updateItem(handle, index) {
      if (handle == "add") {
        if (this.ruleForm.pushData.length <= 4) {
          this.ruleForm.pushData.push({
            ...this.pushDatas,
            // id: this.num,
          });
          if (
            this.ruleForm.pushData.length >= 0 &&
            this.ruleForm.pushData.length <= 1
          ) {
            this.showBut = [false];
          } else {
            this.showBut.push(true);
            this.showBut[0] = true;
          }
        }
      } else {
        this.ruleForm.pushData.splice(index, 1);
        // this.$refs.editableCell.splice(index, 1);
      }
    },
    // 改变查找/查找带值选项及值
    changeSelect(row) {
      // let value = {
      //   value: row.data.id,
      // };
      let options = {
        label: row.data.id,
        value: row.data.name,
      };
      this.$set(this.ruleForm.pushData[this.rowIndex - 1], "val", options);
      this.showSearchTable = false;
    },
    
    async selectes(val) {
      if (val.type === "R") {
        this.getRecordTypes();
      }
      // else if(val.type === "L" && val.fieldId === "ffe202085ACBDD8EusnH"){
      //   this.getCountrySelectValue()
      // }
      else {
        if (val.fieldId === "ffe20120329185449704") {
          return;
        }
        let arr = [];
        let params = {
          fieldId: val.fieldId,
        };
        let res = await GetViewGetSelectValue(params);
        if (res.result) {
          res.data.forEach((item) => {
            let obj = {
              label: item.codekey,
              value: item.codekey,
            };
            arr.push(obj);
            this.selector = arr;
          });
        }
      }
    },
    // 获取国家或地区选项列表值
    
    getCountrySelectValue() {
      getSelectValue({ fieldId: "country" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let options = [];
          res.data.forEach((item) => {
            options.push({
              label: item.codevalue,
              value: item.codekey,
            });
          });
          this.selector = options;
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    
    async getRecordTypes() {
      let arr = [];
      let params = {
        prefix: this.prefix,
      };
      let res = await getRecordType(params);
      if (res.result) {
        res.data.recordTypeList.forEach((item) => {
          let obj = {
            label: item.name,
            value: item.name,
          };
          arr.push(obj);
          this.selector = arr;
        });
      }
    },
    async choseField() {
       //个案 UG.Case.01
      let fieldid =
        this.describeName == this.$i18n.t('UG.Case.01')
          ? "201100000005024CxOSz"
          : "WorkOrderstdobj02021";
      let params = {
        objId: fieldid,
      };
      let resultData = await GetViewInfo(params);
      if (resultData.result) {
        this.choseFieldList = resultData.data.conditionAllFieldList;
        //如果为重大事件自定义字段回显
        let params = JSON.parse(this.$route.query.eventDetail);
        if (params.starttimetype == "2") {
          this.isEventCondition = true;
          if (params.majorEventSetCondition.length != 0) {
            this.ruleForm.pushData = [];
          }
          params.majorEventSetCondition.forEach((ele, index) => {
            this.ruleForm.pushData.push({
              id: index,
              fieldId: ele.fieldId,
              op: ele.operator,
              val: {
                value: ele.value,
              },
              inputType: "input",
            });
          });
          this.ruleForm.pushData.forEach((nav) => {
            this.choseFieldList.forEach((em) => {
              if (nav.fieldId == em.id) {
                nav.type = em.schemefieldType;
                nav.inputType = inputType[em.schemefieldType];
                this.conditions = operator.TYPE[nav.type].getScreeningOpeTypes();
              }
            });
          });
          if (
            this.ruleForm.pushData.length >= 0 &&
            this.ruleForm.pushData.length <= 1
          ) {
            this.showBut = [false];
          } else {
            this.showBut.push(true);
            this.showBut[0] = true;
          }
          this.isedit = true;
        }
      }
    },
  },
};
</script>



<style lang="scss" scoped>
* {
  margin: 0;
}
::v-deep .el-form-item{
  margin-bottom: 0;
}
.fieldValue{
  height: 30px; 
  line-height: 30px; 
  margin-top: 5px;
  margin-left:10px;
  border: 1px solid #DCDFE6;
  width: 185px;
  padding-left:10px;
  border-radius: 4px;
  background-color: #F5F7FA;
  font-size: 12px;
  color: black;
  overflow: hidden; 
}
.range {
  ::v-deep .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
  ::v-deep .el-input__icon {
    line-height: 30px;
  }
  ::v-deep .created-type {
    width: 120px;
    margin-right: 6px;
  }
  ::v-deep .el-form-item__content {
    display: flex;
    align-items: center;
  }
  ::v-deep .select {
    margin-left: 10px;
  }
  ::v-deep .el-tag {
    margin: 2px 0 2px 6px;
  }
  ::v-deep .el-date-editor {
    display: none !important;
  }
}
.event-box {
  padding: 18px 10px;
  .event-header {
    .event-name {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      h3 {
        font-size: 20px;
        color: #333333;
        font-weight: bold;
      }
    }
    .event-Tips {
      h5 {
        font-size: 14px;
        color: #333333;
        a {
          text-decoration: underline;
        }
      }
      h5:first-child {
        margin-bottom: 10px;
      }
    }
  }
  .event-edit {
    margin-top: 23px;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    .event-edit-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      background-color: #f5f5f5;
      border-bottom: 1px solid #dcdcdc;
      h3 {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
      }
      .save-event {
        display: flex;
        align-items: center;
      }
      ::v-deep .el-button {
        height: 26px;
        line-height: 26px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      span:first-child {
        color: red;
      }
      span:last-child {
        font-size: 16px;
        color: #151515;
      }
    }
    .event-edit-detail {
      background-color: #f5f5f5;
      padding: 20px 0 20px 170px;
      .interest-edit-name {
        span:first-child {
          font-size: 16px;
          margin-right: 20px;
        }
        span:last-child {
          font-size: 16px;
          color: #333333;
          font-weight: bold;
        }
      }
      .event-edit-name {
        display: flex;
        align-items: center;
        margin-top: 12px;
        .big-event-name {
          width: 130px;
          text-align: right;
          margin-right: 20px;
          span:first-child {
            color: red;
            font-size: 16px;
          }
          span:last-child {
            font-size: 16px;
            color: #151515;
          }
        }
        ::v-deep .el-input__inner {
          height: 30px;
          line-height: 30px;
        }
        ::v-deep .el-input__icon {
          line-height: 30px;
        }
        ::v-deep .created-type {
          width: 120px;
          margin-right: 6px;
        }
      }
      .event-edit-TimeTriggered {
        display: flex;
        align-items: center;
        margin-top: 12px;
        .TimeTriggered {
          width: 130px;
          text-align: right;
          margin-right: 20px;
          white-space: nowrap;
          span:first-child {
            color: red;
            font-size: 16px;
          }
          span:last-child {
            font-size: 16px;
            color: #151515;
          }
        }
        .TimeTriggered-value {
          display: flex;
          align-items: center;
          ::v-deep .el-input__inner {
            width: 91px;
            height: 30px;
            line-height: 30px;
          }
          img {
            margin-left: 10px;
          }
        }
      }
      .event-edit-starTime {
        display: flex;
        align-items: center;
        margin-top: 12px;
        .start-time {
          width: 130px;
          text-align: right;
          margin-right: 20px;
          span:first-child {
            color: red;
            font-size: 16px;
          }
          span:last-child {
            font-size: 16px;
            color: #151515;
          }
        }
        ::v-deep .el-input__inner {
          height: 30px;
          line-height: 30px;
        }
        ::v-deep .el-input__icon {
          line-height: 30px;
        }
        ::v-deep .created-type {
          width: 120px;
          margin-right: 6px;
        }
      }
      .event-edit-order {
        display: flex;
        align-items: center;
        margin-top: 12px;
        .order {
          width: 130px;
          text-align: right;
          margin-right: 20px;
          span:first-child {
            color: red;
            font-size: 16px;
          }
          span:last-child {
            font-size: 16px;
            color: #151515;
          }
        }
        .order-value {
          display: flex;
          align-items: center;
          ::v-deep .el-input__inner {
            width: 91px;
            height: 30px;
            line-height: 30px;
          }
          img {
            margin-left: 10px;
          }
        }
      }
      .edit-condition {
        margin-top: 28px;
        padding-left: 70px;
        h4 {
          font-size: 14px;
          color: #333333;
          font-weight: bold;
        }
        .Operation-type {
          margin-top: 5px;
          span {
            font-size: 14px;
            color: #333333;
            font-weight: bold;
          }
          span:first-child {
            margin-right: 170px;
          }
          span:last-child {
            margin-left: 151px;
          }
        }
      }
    }
    .save-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 14px 0;
      background-color: #f5f5f5;
      border-top: 1px solid #dcdcdc;
      ::v-deep .el-button {
        height: 26px;
        line-height: 26px;
        padding: 0 20px;
      }
    }
  }
}
</style>
